<script>
    export let worksheets = [];
    import Card from './Card.svelte';
    import { goto } from '$app/navigation';
    import PurchasePaddle from './PurchasePaddle.svelte';
    import Button from './Button.svelte';
    import { format_price } from './utils/format_price';
    import ProductIcon from './ProductIcon.svelte';

    export let h_level = 2;
    let className = '';
    export { className as class };
    export let price_only = false;
    export let horizontal = false;


</script>

{#each worksheets as worksheet}
    <Card
        clickable
        on:click={() => goto(`/worksheets/${worksheet.slug}`)}
        class="{className} flex {horizontal ? 'flex-row gap-4 items-center' : 'flex-col'}"
    >
    
        <ProductIcon
            product={worksheet}
            class="mx-auto my-4 {horizontal ? 'max-sm:hidden ' : ''}"
        />

        <div class="flex-1">
            <svelte:element this={`h${h_level}`} class="mb-2 font-bold text-xl">
                <a href="/worksheets/{worksheet.slug}" class="link"
                    >{@html worksheet.title}</a
                >
            </svelte:element>
            <p class="flex-1">{@html worksheet.lead}</p>
        </div>

        {#if worksheet.price && price_only && !worksheet.purchased}
            <span class="text-lg font-extrabold">{format_price(worksheet.price.price, worksheet.price.currency)}</span>
        {:else if worksheet.price && !worksheet.purchased && !price_only}
            <p class="mt-4">
                <PurchasePaddle
                    product={worksheet}
                    style="small"
                    cta="Buy Now"
                    cta_free="Get for Free"
                    stopPropagation
                />
            </p>
        {:else if !price_only}
            <p class="mt-4"><Button primary outline>Download</Button></p>
        {/if}
    </Card>
{/each}
